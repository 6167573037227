<template>
  <div class="box-infos">
    <van-nav-bar :title="Content.title" left-text="" left-arrow @click-left="$router.go(-1)" />
    <!-- 头部 -->
    <div class="hu_news_title">
      <div>
        <h2>{{Content.title}}</h2>
        <h6>{{Content.datetime}}</h6>
      </div>
    </div>
    <div class="infos-title" v-html="Content.content">
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRoute,useRouter } from 'vue-router'
import {djarticlelist,djarticleinfo,djmenulist} from '@/api/index'
export default {
  name: "informationMore",
  setup() {
    let Content=ref({});
    let $route = useRouter();
    let bl = {
      guid: $route.currentRoute.value.params.id,
    };
    const Details=()=>{
      djarticleinfo(bl).then((res) => {
        Content.value=res.data.mydata;
        console.log('iangq',res.data.mydata)
      }).catch((err) => {
        console.log(err)
      });
    };
    onMounted(()=>{
      Details();
    });
    return {
      Content,
      Details,
    };
  },
};
</script>

<style scoped lang="less">
.box-infos {
  width: 100%;
  margin-bottom: 3rem;
  .hu_news_title{
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    div{
      border-bottom:1px dotted #cccc;
      h2{
        color: rgb(66, 66, 66);
        text-align: center;
        font-size: 1.2rem;
        line-height: 2.2rem;
      }
      h6{
        text-align: center;
        color: rgb(102, 102, 102);
        margin-top: 0.5rem;
      }
    }
  }
  .infos-title {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 0.5rem;
  }
  .biaoqian {
    padding: 0 0.5rem;
    font-size: 0.8rem;
    color: #929292;
  }
}
.info-htmls{
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 0.5rem;
    img{
        width: 100%;
    }
}
/deep/ .van-nav-bar .van-icon {
  color: #3f3f3f;
}
/deep/ .van-nav-bar__title {
  max-width: 60%;

  font-size: 0.98rem;
}
</style>